:root {
    --Gray-0: #f8f9fa;
    --Gray-1: #f1f3f5;
    --Gray-2: #e9ecef;
    --Gray-3: #dee2e6;
    --Gray-4: #ced4da;
    --Gray-5: #adb5bd;
    /* background-color or  default font color*/
    --Gray-6: #868e96;
    --Gray-7: #495057;
    /* default font color or background-color*/
    --Gray-8: #343a40;
    --Gray-9: #212529;
    /* /////////////////////////////////////////////////////////// */
    --Red-0: #fff5f5;
    --Red-1: #ffe3e3;
    --Red-2: #ffc9c9;
    --Red-3: #ffa8a8;
    --Red-4: #ff8787;
    --Red-5: #ff6b6b;
    --Red-6: #fa5252;
    --Red-7: #f03e3e;
    --Red-8: #e03131;
    --Red-9: #c92a2a;
    /* /////////////////////////////////////////////////////////// */

    --Violet-0: #f3f0ff;
    --Violet-1: #e5dbff;
    --Violet-2: #d0bfff;
    --Violet-3: #b197fc;
    --Violet-4: #9775fa;
    --Violet-5: #845ef7;
    --Violet-6: #7950f2;
    --Violet-7: #7048e8;
    --Violet-8: #6741d9;
    --Violet-9: #5f3dc4;
    /* /////////////////////////////////////////////////////////// */
    --Indigo-0: #edf2ff;
    --Indigo-1: #dbe4ff;
    --Indigo-2: #bac8ff;
    --Indigo-3: #91a7ff;
    --Indigo-4: #748ffc;
    --Indigo-5: #5c7cfa;
    --Indigo-6: #4c6ef5;
    --Indigo-7: #4263eb;
    --Indigo-8: #3b5bdb;
    --Indigo-9: #2c42ad;
    /* /////////////////////////////////////////////////////////// */
    --Blue-0: #e7f5ff;
    --Blue-1: #d0ebff;
    --Blue-2: #a5d8ff;
    --Blue-3: #74c0fc;
    --Blue-4: #4dabf7;
    --Blue-5: #339af0;
    --Blue-6: #228be6;
    --Blue-7: #1c7ed6;
    --Blue-8: #1971c2;
    --Blue-9: #1864ab;
    /* main and logo color*/
    /* /////////////////////////////////////////////////////////// */
    --Green-0: #ebfbee;
    --Green-1: #d3f9d8;
    --Green-2: #b2f2bb;
    --Green-3: #8ce99a;
    --Green-4: #69db7c;
    --Green-5: #51cf66;
    --Green-6: #40c057;
    --Green-7: #37b24d;
    --Green-8: #2f9e44;
    --Green-9: #2b8a3e;
    /* /////////////////////////////////////////////////////////// */
    --Lime-0: #f4fce3;
    --Lime-1: #e9fac8;
    --Lime-2: #d8f5a2;
    --Lime-3: #c0eb75;
    --Lime-4: #a9e34b;
    --Lime-5: #94d82d;
    --Lime-6: #82c91e;
    --Lime-7: #74b816;
    --Lime-8: #66a80f;
    --Lime-9: #5c940d;
    /* /////////////////////////////////////////////////////////// */
    --Yellow-0: #fff9db;
    --Yellow-1: #fff3bf;
    --Yellow-2: #ffec99;
    --Yellow-3: #ffe066;
    --Yellow-4: #ffd43b;
    --Yellow-5: #fcc419;
    --Yellow-6: #fab005;
    --Yellow-7: #f59f00;
    --Yellow-8: #f08c00;
    --Yellow-9: #e67700;
    /* /////////////////////////////////////////////////////////// */
    --Orange-0: #fff4e6;
    --Orange-1: #ffe8cc;
    --Orange-2: #ffd8a8;
    --Orange-3: #ffc078;
    --Orange-4: #ffa94d;
    --Orange-5: #ff922b;
    --Orange-6: #fd7e14;
    --Orange-7: #f76707;
    --Orange-8: #e8590c;
    --Orange-9: #d9480f;
}

*:focus {
    outline: none;
    outline-width: 0;
}

* {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font-family: Arial, sans-serif;
}

#root {
    display: grid;
    grid-template-areas: "header" "body" "footer";
    grid-template-rows: minmax(fit-content, auto) minmax(fit-content, 1fr) minmax(
            min-content,
            auto
        );
    background-color: #f7eec6;
    background: url(/src/shared/img/ezcode_Web_background.jpg);
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    padding: 0;
}

.body {
    grid-area: body;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.ezcode {
    font-family: "Archivo Black", sans-serif;
    font-size: 9vw;
    letter-spacing: 0.2rem;
    text-align: center;
    transform: scaleY(1.5);
    color: #3b3b3b;
}

.data_science_inc {
    font-family: "Archivo Black", sans-serif;
    font-size: 7vw;
    letter-spacing: 0.1rem;
    text-align: center;
    color: #4d638a;
    filter: drop-shadow(3px 3px 3px #5a7681);
    -webkit-text-stroke: 0.5px rgb(214, 214, 214);
}

.body > h1 a {
    text-decoration: none;
    font-family: "Archivo Black", sans-serif;
    font-size: 3vw;
    letter-spacing: 0.2rem;
    text-align: center;
    color: #525f75;
}

.body > h1 a:hover {
    text-decoration: underline;
    color: #084183;
}

.body img {
    width: 50%;
    filter: drop-shadow(5px 5px 5px #5a7681);
}
